<template>
  <div>
    <div class="mass-action-settings">

      Долгое массовое действие для проверки настроек сервера

      <el-popconfirm
        confirm-button-text='Да'
        cancel-button-text='нет'
        icon="el-icon-question"
        title="Вы уверены, что хотите запустить действие?"
        @confirm="doAction"
      >
        <el-button
          slot="reference"
          size="small"
          type="primary"
        >
          Создать
        </el-button>

      </el-popconfirm>

    </div>

    <mass-action-result
      v-if="result"
      :result="result"
    ></mass-action-result>
  </div>
</template>

<script>
import {ActionTemplateMixin} from "@/components/massActions/actionTemplates/ActionTemplateMixin";
import MassActionResult from "@/components/massActions/MassActionResult.vue";
import {mapGetters} from "vuex";

export default {
  name: "user-create-card-action",
  mixins: [ActionTemplateMixin],
  components: {MassActionResult},

  computed: {
    ...mapGetters(['user']),
  },
  watch: {

  },
  data() {
    return {
    }
  },
  methods: {

  },
}
</script>
